import React from 'react';

export default function Event({ event, showDate }) {
    return (
        <article className='event'>
            <a className='event-image-link' style={{ backgroundColor: event.color || 'white' }} href={event.url || '#'}>
                <img className='event-image' src={getEventImageUrl()} />
            </a>
            <div className='event-content'>
                <h4 className="major">{event.title}</h4>
                {event.cancelled === true ? <img className='event-cancelled' src={require(`../assets/images/cancelled.png`)} /> : null}
                {showDate === true ? <a href={event.url || '#'} className='date'>{new Date(event.date).toDateString()}</a> : null}
                {showDate !== true ?
                    <div className='materials'>
                        {event.slides !== undefined ? <a key='event-slides' href={event.slides} className='fas fa-tv'></a> : null}
                        {event.video !== undefined ? <a key='event-video' href={event.video} className='fab fa-youtube'></a> : null}
                    </div>
                    : null}
            </div>
        </article>
    )

    function getEventImageUrl() {
        let url
        try {
            url = require(`../assets/images/events/${event.id}.png`)
        } catch (error) {
            try {
                url = (event.conference !== undefined) ?
                    require(`../assets/images/events/${event.conference}.png`)
                    : require(`../assets/images/events/${event.family}.png`)
            } catch (error) {
                url = require(`../assets/images/events/${event.family}.png`)
            }

        }
        return url
    }
}
