import React from 'react';
import Microlink from '@microlink/react'

export default function Feedbacks({ feedbacks }) {
    return (feedbacks !== undefined  && feedbacks.length !=0 ?
        <section className='feedbacks'>
            <h3 className='major'>Feedbacks</h3>

            <section className='feedbacks-previews'>
                {feedbacks.map(feedback => <Microlink key={feedback.url} url={feedback.url} size='large' />)}
            </section>
        </section>
        : null)
}
