import React from 'react';

export default function BBLs({ bbls }) {
    return (
        <article className='bbl event'>
            <a href='https://www.brownbaglunch.fr/baggers.html#julien-topcu' className='event-image-link' style={{ backgroundColor: 'white' }}>
                <img className='event-image' src={getEventImageUrl()} />
            </a>
            <div className='event-content'>
                <h4 className="major">Brown Bag Lunches</h4>
                <div className="materials">
                    {bbls.map((bbl, index) => <div key={`bbl-${index}`} className='bbl-title'>{bbl}</div>)}
                </div>
            </div>
        </article>
    )

    function getEventImageUrl() {
        return require(`../assets/images/events/bbl.png`)
    }
}
