import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';


export default function Tweets({ tweets }) {
    return (false && tweets !== undefined && tweets.length > 0 ?
        <div>
            <h3 className='major tweets-header'><i className='fab fa-twitter' />Tweets</h3>
            <div className='tweets'>
                {
                    tweets.map(tweet =>
                        <TwitterTweetEmbed
                            id={tweet.id}
                            tweetId={tweet.id}
                        />)
                }
            </div>
        </div>
        : null
    )
}