import React from 'react';
import Microlink from '@microlink/react'

export default function Interviews({ interviews }) {
    return (interviews !== undefined && interviews.length != 0?
        <section className='interviews'>
            <h3 className='major'>Interviews</h3>

            <section className='interviews-previews'>
                {interviews.map(interview => <Microlink url={interview.url} size='large' />)}
            </section>
        </section>
        : null)
}
