import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import LanguageFlag from './LanguageFlag';
import Slides from './Slides';
import Video from './Video';
import CoSpeakers from './CoSpeakers';
import Repository from './Repository';
import Articles from './Articles';

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
}));

export default function TalkDescription ({ talk }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { languages, cospeakers, materials } = talk;

  return (
    <div className={`talk-description ${classes.root}`}>
      <hr />
      <CoSpeakers cospeakers={cospeakers} />
      <div className='i18n-container'>
        <AppBar className='' position='static'>
          <Tabs className='languages' value={value} onChange={handleChange}>
            {Object.entries(languages).map((language, index) => (
              <Tab
                key={language[0]}
                label={talkDescriptionLabel(language)}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </AppBar>
        {Object.keys(languages).map((language, index) => {
          const { description, defaultEvent } = languages[language];
          const { url: slides } = materials.find(
            material =>
              material.type === 'SLIDES' && material.language === language
          ) || { url: undefined };
          const { video } = findVideo(defaultEvent);
          const repository = materials.find(
            material => material.type === 'REPOSITORY'
          );
          const articles = materials.filter(
            material => material.type === 'ARTICLE'
          );
          return (
            <TabPanel
              key={language}
              className='i18n-description'
              value={value}
              index={index}
            >
              {repository !== undefined ? (
                <Repository repository={repository} />
              ) : null}
              {
              description.map(line => (
                <p>{line}</p>
              ))
              }
              {slides !== undefined ? <Slides url={slides} /> : null}
              {video !== undefined ? <Video url={video} /> : null}
              {articles !== undefined && articles.length > 0 ? (
                <Articles articles={articles} />
              ) : null}
            </TabPanel>
          );
        })}
      </div>
    </div>
  );

  function talkDescriptionLabel (language) {
    const { video } = findVideo(language[1].defaultEvent);
    return (
      <div className='talk-description-label'>
        <LanguageFlag language={language[0]} />
        {video !== undefined ? <i className='fas fa-video' /> : null}
      </div>
    );
  }

  function findVideo (defaultEvent) {
    return talk.events.find(event => event.id === defaultEvent);
  }
}
