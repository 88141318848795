import React from 'react';

import Layout from '../components/Layout';
import TalkDescription from '../components/TalkDescription';
import { navigateTo } from 'gatsby';
import LanguageFlag from '../components/LanguageFlag';
import Events from '../components/Events';
import Feedbacks from '../components/Feedbacks';
import Interviews from '../components/Interviews';
import { isFutureEvent } from '../utils/utils';
import Tweets from '../components/Tweets';

export default ({ '*': talkId }) => {
  try {
    const talk = require(`../data/talks/${talkId}.js`);

    const { defaultLanguage, languages, tweets } = talk;
    const { title } = languages[defaultLanguage];
    return (
      <Layout fullMenu>
        <section id='wrapper' className='talk'>
          <header>
            <div className='inner'>
              <TalkTitle
                key={defaultLanguage}
                className='default-title'
                title={title}
                language={defaultLanguage}
              />
              {Object.keys(languages)
                .filter(language => language !== defaultLanguage)
                .map(language => (
                  <TalkTitle
                    key={language}
                    className='alternate-title'
                    title={languages[language].title}
                    language={language}
                  />
                ))}
            </div>
          </header>
        </section>
        <section className='wrapper'>
          <div className='inner'>
            <div className='col-12'>
              <Events
                title='Upcoming Events'
                events={upcomingEvents(talk.events)}
                showDates={true}
              />
              <TalkDescription talk={talk} />
              <Interviews interviews={talk.interviews} />
              <Feedbacks feedbacks={talk.feedbacks} />
              <Events title='Past Events' events={pastEvents(talk.events)} />
              <Tweets tweets={tweets} />
            </div>
          </div>
        </section>
      </Layout>
    );
  } catch {
    if (typeof window !== 'undefined') {
      navigateTo('404');
    }
    return null;
  }
};

const TalkTitle = ({ title, language, className }) => (
  <div className={`talk-title ${className}`}>
    {title}
    <LanguageFlag language={language} />
  </div>
);
function pastEvents (events) {
  return events.filter(event => !isFutureEvent(event));
}

function upcomingEvents (events) {
  return events.filter(event => isFutureEvent(event));
}
