import React from 'react';
import { events as allEvents } from '../data/events';
import Event from '../components/Event';
import BBLs from './BBLs';

export default function Events ({ events, title, showDates }) {
  return events !== undefined && events.length > 0 ? (
    <div className='events'>
      <h3 className='major'>{title}</h3>
      <section className='features'>
        {resolveNonBBLEvents().map(event => (
          <Event key={event.id} event={event} showDate={showDates} />
        ))}
        {getBBLTitles().length != 0 ? (
          <BBLs key='bbls' bbls={getBBLTitles()} />
        ) : null}
      </section>
    </div>
  ) : null;

  function resolveNonBBLEvents () {
    return allEvents
      .filter(
        event =>
          event.family !== 'bbl' &&
          events.map(talkEvent => talkEvent.id).includes(event.id)
      )
      .map(event =>
        Object.assign(
          {},
          event,
          events.find(talkEvent => talkEvent.id === event.id)
        )
      );
  }

  function getBBLTitles () {
    return allEvents
      .filter(
        event =>
          event.family === 'bbl' &&
          events.map(talkEvent => talkEvent.id).includes(event.id)
      )
      .map(event => event.title);
  }
}
