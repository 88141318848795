import React, { Fragment } from 'react';

export default function Articles({ articles }) {
    return (
        <div className='articles'>
            <div className='articles-header'>
                <img src={require('../assets/images/beyondxscratch.png')} />
                <h3>Blog Posts</h3>
            </div>
            <hr/>
            <ul className='articles-content'>
                {
                    articles.map(article =>
                        <li key={article.url}>
                            <a className='article' href={article.url}>
                                <img src={article.icon} />
                                <div className='article-title'>{article.title}</div>
                            </a>
                        </li>)
                }
            </ul>
        </div>
    )
}
